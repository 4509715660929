import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import SimpleFormLayout from "./layouts/SimpleForm";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
import EventProductList from "./pages/products/EventProductList";
import Users from "./pages/auth/Users";
import Orders from "./pages/finance/Orders";
import Sales from "./pages/finance/Sales";
import BankRecon from "./pages/finance/BankRecon";
import BankStatementUpload from "./pages/finance/BankStatementUpload";
import VenueList from "./pages/venue/VenueList";
import Profile from "./pages/auth/Profile";
import BankReconDetail from "./pages/finance/BankReconDetail";
import SalesDetail from "./pages/finance/SalesDetail";
import OrdersDetail from "./pages/finance/OrdersDetails";
import EventProductEdit from "./pages/products/EventProductEdit";
import CreateStation from "./pages/station/CreateStation";
import CreateEventPage from "./pages/events/CreateEvent";
import StockTransferPage from "./pages/Stock/StockTransferPage";
import EventProductBatchEdit from "./pages/products/EventProductBatchEdit";
import CreateVenue from "./pages/venue/CreateVenue";
import UpdateVenue from "./pages/venue/UpdateVenue";
import StationList from "./pages/station/StationList";
import UpdateStation from "./pages/station/UpdateStation";
import CreateUser from "./pages/auth/CreateUser";
import UpdateUser from "./pages/auth/UpdateUser";
import EventList from "./pages/events/EventList";
import UpdateEvent from "./pages/events/UpdateEvent";
import MerchantAllocation from "./pages/events/MerchantAllocation";
import VendorList from "./pages/company/VendorList";
import UpdateVendor from "./pages/company/UpdateVendor";
import SettingsForm from "./pages/settings/SettingsForm";
import SettingsList from "./pages/settings/SettingsList";
import CreateVendor from "./pages/company/CreateVendor";
import PromoterList from "./pages/company/PromoterList";
import CreatePromoter from "./pages/company/CreatePromoter";
import UpdatePromoter from "./pages/company/UpdatePromoter";
import WorldTourList from "./pages/tours/WorldTourList";
import CountryTourList from "./pages/tours/CountryTourList";
import CreateCountryTour from "./pages/tours/CreateCountryTour";
import UpdateCountryTour from "./pages/tours/UpdateCountryTour";
import CreateWorldTour from "./pages/tours/CreateWorldTour";
import UpdateWorldTour from "./pages/tours/UpdateWorldTour";
import SignOut from "./components/auth/SignOut";
import StockList from "./pages/Stock/StockList";
import ManageVendorEvent from "./pages/events/ManageVendorEvent";
import CommissionPage from "./pages/commission/Commission";
import EventStationProductBatchEdit from "./pages/products/EventStationProductBatchEdit";
import StockReconcilePage from "./pages/Stock/StockReconcilePage";
import ImportUsers from "./pages/auth/ImportUsers";
import EventProductImport from "./pages/products/EventProductImport";
import AssessmentCreate from "./pages/assessments/AssessmentCreate";
import AssessmentUpdate from "./pages/assessments/AssessmentUpdate";
import AssessmentRead from "./pages/assessments/AssessmentRead";
import AssessmentsHistory from "./pages/assessments/AssessmentsHistory";
import ToolUpdate from "./pages/tools/ToolUpdate";
import ToolRead from "./pages/tools/ToolRead";
import ToolCreate from "./pages/tools/ToolCreate";
import FormBuilder from "./pages/form_builder/FormBuilder";
import CandidatePage from "./pages/candidates/CandidatesPage";
import CandidatesPageEdit from "./pages/candidates/CandidatesPageEdit";
import CandidateToolRegistration from "./pages/candidates/RiskAssessmentPage";
import CandidateViewPage from "./pages/candidates/CandidateViewPaga";
import CandidateProgress from "./pages/candidates/CandidateProgress";
import AssessmentsFeedbackPage from "./pages/assessments/AssessmentFeedbackPage";

//Indicators
import PerformanceIndicatorList from "./pages/Indicator/PerformanceIndicatorList";
import PerformanceIndicatorForm from "./pages/Indicator/PerformanceIndicatorForm";
import CandidateCreate from "./pages/candidates/CandidateCreate";
import CandidateUpdate from "./pages/candidates/CandidateUpdate";
import CandidateUser from "./pages/auth/CandidateUser";
import CandidateFeaturesPage from "./pages/candidates/CandidateFeaturesPage";
import AdminFeaturePage from "./pages/assessments/AdminFeaturePage";
import ToolSupplyMapping from "./pages/tools/ToolSupplyMapping";
import ToolDemandMapping from "./pages/tools/ToolDemandMapping";
import ActionPlanPage from "./pages/actionPlan/ActionPlanPage";
import CandidateSignOutCreate from "./pages/candidates/CandidateSignOutCreate";
import MatchmakingTool from "./pages/MatchmakingTool/MatchmakingTool";
import GapAssessment from "./pages/tools/GapAssessment";
import Panel from "./pages/dashboards/Default/Panel";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "assessments",
    element: <DashboardLayout />,
    children: [
      {
        path: "create",
        element: <AssessmentCreate />,
      },
      {
        path: "search",
        element: <AssessmentRead />,
      },
      {
        path: "edit",
        element: <AssessmentUpdate />,
        param: "AssessmentId/:candidateId?",
      },
      {
        path: "assessmentsHistory",
        element: <AssessmentsHistory />,
        param: "AssessmentId/:candidateId?",
      },
    ],
  },
  {
    path: "candidates",
    element: <DashboardLayout />,
    children: [
      {
        path: "search",
        element: <CandidatePage />,
      },
      {
        path: "panel",
        element: <Panel />,
      },
      {
        path: "edit",
        element: <CandidatesPageEdit />,
        param: "AssessmentId",
      },
      {
        path: "create",
        element: <CandidateToolRegistration />,
      },
      {
        path: "view",
        element: <CandidateViewPage />,
      },
      {
        path: "progress",
        element: <CandidateProgress />,
      },
      {
        path: "info-create",
        element: <CandidateCreate />,
      },
      {
        path: "info-Update",
        element: <CandidateUpdate />,
      },
    ],
  },
  {
    path: "tools",
    element: <DashboardLayout />,
    children: [
      {
        path: "create",
        element: <ToolCreate />,
      },
      {
        path: "search",
        element: <ToolRead />,
      },
      {
        path: "edit",
        element: <ToolUpdate />,
        param: "ToolId",
      },
      {
        path: "form",
        element: <FormBuilder />,
      },
    ],
  },
  {
    path: "feedback",
    element: <DashboardLayout />,
    children: [
      {
        path: "view",
        element: <AssessmentsFeedbackPage />,
      },
    ],
  },
  {
    path: "Indicators",
    element: <DashboardLayout />,
    children: [
      {
        path: "search",
        element: <PerformanceIndicatorList />,
      },
    ],
  },
  {
    path: "settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "create",
        element: <SettingsForm />,
      },
      {
        path: "search",
        element: <SettingsList />,
      },
      {
        path: "edit",
        element: <SettingsForm />,
        param: "settingId",
      },
    ],
  },
  {
    path: "finance",
    element: <DashboardLayout />,
    children: [
      {
        path: "bank-statement-upload",
        element: <BankStatementUpload />,
      },
      {
        path: "recon",
        element: <BankRecon />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "sales",
        element: <Sales />,
      },
      {
        path: "recondetail",
        element: <BankReconDetail />,
      },
      {
        path: "saledetail",
        element: <SalesDetail />,
      },
      {
        path: "orderdetail",
        element: <OrdersDetail />,
      },
      {
        path: "commission",
        element: <CommissionPage />,
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Users />,
      },
      {
        path: "import-users",
        element: <ImportUsers />,
      },
      {
        path: "add-user",
        element: <CreateUser />,
      },
      {
        path: "edit-user",
        element: <UpdateUser />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "logout",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <SignOut />,
      },
    ],
  },
  {
    path: "tool",
    element: <DashboardLayout />,
    children: [
      {
        path: "matchmaking",
        element: <MatchmakingTool />,
      },
    ],
  },
  {
    path: "form",
    element: <SimpleFormLayout />,
    children: [
      {
        path: "info-create",
        element: <CandidateSignOutCreate />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "signup",
        element: <CandidateUser />,
      },
      {
        path: "info-create",
        element: <CandidateSignOutCreate />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "assessment-candidate",
    element: <DashboardLayout />,
    children: [
      {
        path: "options",
        element: <CandidateFeaturesPage />,
      },
      {
        path: "info-Update",
        element: <CandidateUpdate />,
      },
      {
        path: "search",
        element: <CandidatePage />,
      },
    ],
  },
  {
    path: "assessment-admin",
    element: <DashboardLayout />,
    children: [
      {
        path: "options",
        element: <AdminFeaturePage />,
      },
      {
        path: "info-Update",
        element: <CandidateUpdate />,
      },
      {
        path: "search",
        element: <CandidatePage />,
      },
      {
        path: "supply-mapping",
        element: <ToolSupplyMapping />,
      },
      {
        path: "demand-mapping",
        element: <ToolDemandMapping />,
      },
      {
        path: "qualification",
        element: <CandidateProgress />,
      },
      {
        path: "action-plan",
        element: <ActionPlanPage />,
      },
      {
        path: "gap-assessment",
        element: <GapAssessment />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
