import React from "react";
import CandidateForm from "./CandidateForm";
import { ToastContainer } from "react-toastify";

const CandidateSignOutCreate: React.FC = () => {
  return (
    <>
      <ToastContainer position="top-right" />
      <CandidateForm id={0} userType={""} />
    </>
  );
};

export default CandidateSignOutCreate;
