import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Stepper,
  StepLabel,
  Step,
  StepIconProps,
  StepIcon,
} from "@mui/material";
import { Box, color, spacing } from "@mui/system";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
// import OlogaMultiInput from "../../components/Combos/OlogaMultiInput";
import Item from "../../components/Combos/Item";
import useAuth from "../../hooks/useAuth";
import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CancelButton from "../../components/Buttons/CancelButton";
import SubmitButton from "../../components/Buttons/SubmitButton";
import axios from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import ImageCompressor from "../../hooks/ImageCompressor";
import OlogaLoading from "../../components/misc/OlogaLoading";
import { Answer, AssessmentDto, Question, Tool } from "./data_mapping";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { string } from "yup";
import OlogaAutocomplete from "../../components/select/OlogaAutocomplete";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const FormBuilder: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [media, setMedia] = useState<FormData>();
  const [AssessmentScoreDate, setAssessmentScoreDate] = useState("");
  const [questions, setQuestions] = useState<Question[]>([]);
  const [productBrands, setProductBrands] = useState<Item[]>([]);
  const [candidates, setCandidates] = useState<Item[]>([]);
  const [assessmentDtos, setAssessmentDtos] = useState<AssessmentDto[]>([]);
  const map = new Map();
  const [mapDataDto, setMapDataDto] = useState<Map<number, AssessmentDto>>(
    new Map()
  );
  const [dataDTO, setDataDto] = useState<Map<string, string>>(new Map());
  const [radioValues, setRadioValues] = useState<Map<string, string>>(
    new Map()
  );
  const [dataForm, setDataForm] = useState({});
  const [textControllerState, setTextControllerState] = useState({ id: null });
  const [sizeType, setSizeType] = useState("");
  const [radio, setRadio] = useState("");
  const [assessmentDate, setAssessmentDate] = useState<any | " ">("");
  const [submitted, setSubmitted] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
  const [previousValue, setPreviousValue] = useState();
  const [currentValue, setCurrentValue] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [showCandidates, setShowCandidate] = useState(false);
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [assessmentId, setAssessmentID] = useState(0);
  const [tool, setTool] = useState<Tool>({
    id: 0,
    name: "",
    description: "",
  });
  const [selectedCandidate, setSelectedCandidate] = useState<any>();
  const [textController, setTextController] = useState({
    questionId: 0,
    answer: "",
  });
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<[]>([]);
  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;
  };

  const [assessmentAnswers, setAssessmentAnswers] = useState([
    {
      id: null,
      updated: false,
      score: null,
      name: "",
      description: "",
      question: {
        id: null,
        updated: false,
        name: "",
        description: "",
        category: {
          id: null,
          updated: false,
          code: null,
          type: "",
          description: "",
          parentId: null,
          state: null,
          vendorId: null,
        },
        type: {
          id: null,
          updated: false,
          code: "",
          type: "",
          description: "",
          parentId: null,
          state: null,
          vendorId: null,
        },
        tool: {
          id: null,
          updated: false,
          name: "",
          description: "",
          assessment: {
            id: null,
            updated: false,
            name: "",
          },
        },
      },
    },
  ]);

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    retrieveQuestionsCategories(state.toolId);
    retrieveQuestions(state.toolId);
    retrieveTool(state.toolId);
    retrieveCandidates();
    retrieveAssessmentScoreItems(state.toolId, state.id);
    if (state.candidateId) {
      const formattedDate = formatDate(state.assessmentDate);
      setAssessmentDate(dayjs(formattedDate));
    }

    console.log("Data form " + dataForm);
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    question: Question
  ) => {
    setSubmitted(false);
    const { name, value } = event.target;
    const property = "answers.".concat(question.id.toString());
    //updateVariable(productData, property, value, null, question);
    //setAnswerDto(answer, question);
  };

  const handleInputChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    setSubmitted(false);
    const { name, value } = event.target;
    console.log(name, value);
    //updateVariable(productData, name, value, null, question);
    //setRadio(value);
    //radioValues.set(question.description, value);
  };

  const handleSelectChange = (answer: Answer, question: Question) => {
    updateVariable(dataForm, `${question.id}`, answer, null);
    setAnswerDto(answer, question);
  };

  const handleSelectChangeCandidate = (candidate: Item) => {
    setSelectedCandidate(candidate);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleMultiSelectChange = (
    componentName: string,
    selected: Item[],
    e: any,
    question: Question
  ) => {
    // updateVariable(productData, componentName, selected, e, question);
  };

  const handleImageUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    const fileBytes = event.target.files?.[0]; // Get the first selected file

    if (fileBytes) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result || null);
      };
      reader.readAsDataURL(fileBytes as Blob);
    }

    const formData = await ImageCompressor(
      fileBytes as Blob,
      fileBytes?.name as string
    );

    setMedia(formData);
    // updateVariable(productData, "file", formData, null, question);
  };

  const setAnswerDto = (answer: Answer, question: Question) => {
    const newAssessmentDto: AssessmentDto = {
      flag: question.name,
      candidateId:
        state?.candidateId != null ? state?.candidateId : selectedCandidate.id,
      assessmentId: question.tool.assessment.id,
      toolId: question.tool.id,
      questionId: question.id,
      answer: {
        id: answer.id,
        name: answer.description,
        question: { id: question.id },
        score: answer.score,
        description: answer.description,
      },
      assessmentDate: "",
    };
    mapDataDto.set(question.id, newAssessmentDto);
  };

  const updateVariable = (
    object: any,
    propertyName: string,
    newValue: any,
    e: any
  ) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setDataForm({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setDataForm({ ...currentObject });
    }
  };

  const retrieveQuestionsCategories = async (toolId: number) => {
    const response = await axios.get(
      `simple-entity/questioncategories/${toolId}`
    );
    setSteps(
      response.data.map((e: any) => {
        return {
          id: e?.id,
          type: e?.type,
          description: e?.description,
        };
      })
    );

    setActiveStep(response.data[0].id);
  };

  const retrieveQuestions = async (toolId: number) => {
    const response = await axios.get(`questions/${toolId}`);
    setQuestions(
      response.data.map((e: any) => {
        return {
          id: e?.id,
          name: e?.name,
          description: e?.description,
          category: e?.category,
          categoryId: e?.category.id,
          type: e?.type,
          tool: e?.tool,
          answers: e.answers,
        };
      })
    );
  };

  const retrieveTool = async (toolId: number) => {
    const response = await axios.get(`tools/${toolId}`);
    setTool(response.data);
    setAssessmentID(response.data.assessment.id);
  };

  const retrieveAssessmentScoreItems = async (
    toolId: number,
    assessmentScoreId: number
  ) => {
    if (state.id) {
      const response = await axios.get(
        `assessments/getAssessmentScoreItems/${toolId}/${assessmentScoreId}`
      );

      (response as any).data.forEach((element: any) => {
        updateVariable(
          dataForm,
          `${element.question.id}`,
          element.answer,
          null
        );
        mapDataDto.set(element.question.id, element);
      });
    }
  };

  const retrieveCandidates = async () => {
    const response = await axios.get(`candidate/list`);
    setCandidates(
      response.data.map((e: any) => {
        return {
          id: e.id,
          description: e.firstName,
          type: e.firstName,
          name: e.firstName,
          value: e.id,
        };
      })
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    var response;

    try {
      var dtoAssessScoreItems: any = [];
      mapDataDto.forEach((value, key) => {
        value.assessmentDate = assessmentDate;
        value.candidateId =
          state?.candidateId != null
            ? state?.candidateId
            : selectedCandidate.id;
        dtoAssessScoreItems.push(value);
      });

      response = await axios.post(
        `assessments/assessmentScore`,
        dtoAssessScoreItems
      );

      navigate(-1);
      toast.success("Operação realizado com sucesso");
    } catch (error) {
      toast.error("Ocorreu um erro ao gravar: " + error);
    }

    setShowLoading(false);
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={state?.toolDesc}
        items={[{ label: "Tool", url: "/assessments/search" }]}
      />
      <Card mb={12}>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={3} md={6}>
              <p></p>
              <OlogaGenericCombo
                label={"Candidate"}
                name="candidateId"
                items={candidates}
                value={selectedCandidate || ""}
                onChange={handleSelectChangeCandidate}
              />
            </Grid>
            <Grid item md={6} my={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Assessment Date"
                  format="DD/MM/YYYY"
                  value={dayjs(state.assessmentDate)}
                  onChange={(date) => {
                    setAssessmentDate(
                      date ? dayjs(date.format("DD/MM/YYYY"), "DD/MM/YYYY") : ""
                    );
                  }}
                  slotProps={{
                    textField: {
                      name: "assessmentDate",
                      fullWidth: true,
                      variant: "outlined",
                      helperText: "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <br />
          <br />
          <Stepper activeStep={activeStep}>
            {steps.map((i) => (
              <Step
                key={(i as any).description}
                onClick={() => handleStepChange((i as any).id)}
              >
                <StepLabel>{(i as any).description}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />
          <form onSubmit={handleSubmit}>
            {true && (
              <Box p={3}>
                <Grid container spacing={2} marginBottom={3}>
                  {questions.map((question: Question) => {
                    const matchingAnswers = assessmentAnswers.filter(
                      (a) => a?.question.id === question.id
                    );
                    switch (question.type.code.toLowerCase()) {
                      case "dropdown":
                        return (question as any).categoryId === activeStep ? (
                          <Grid item xs={5} key={question.id} md={6}>
                            <p>{question.description}</p>
                            <OlogaGenericCombo
                              label={""}
                              name={`dataForm.${question.id}`}
                              items={question.answers}
                              value={mapDataDto?.get(question.id)?.answer || ""}
                              onChange={(valueInput: Answer) =>
                                handleSelectChange(valueInput, question)
                              }
                              required={false}
                            />
                          </Grid>
                        ) : null;
                      case "text":
                        return (
                          <Grid item xs={6} key={question.id}>
                            <p>{question.name}</p>
                            <TextField
                              key={question.id}
                              fullWidth
                              name={question.description}
                              /*   value={
                              matchingAnswers.length > 0
                                ? matchingAnswers[0]?.description
                                : ""
                            } */
                              variant="outlined"
                              onChange={(valueInput) => {
                                const newText = {
                                  questionId: question.id,
                                  answer: valueInput.target.value,
                                };

                                if (matchingAnswers.length > 0) {
                                  matchingAnswers[0].description =
                                    valueInput.target.value;
                                  matchingAnswers[0] = matchingAnswers[0];
                                }

                                setTextController((prev) => ({
                                  ...prev,
                                  newText,
                                }));
                                handleInputChange(
                                  valueInput as React.ChangeEvent<HTMLTextAreaElement>,
                                  question
                                );
                              }}
                              required
                              error={submitted && !question.name}
                              helperText={
                                submitted &&
                                !question.name &&
                                "This field is required"
                              }
                            />
                          </Grid>
                        );
                      case "number":
                        return (
                          <Grid item xs={6} key={question.id}>
                            <p>{question.name}</p>

                            <TextField
                              key={question.id}
                              fullWidth
                              name={question.description}
                              variant="outlined"
                              onChange={(valueInput) => {
                                const newText = {
                                  questionId: question.id,
                                  answer: valueInput.target.value,
                                };
                                setTextController((prev) => ({
                                  ...prev,
                                  newText,
                                }));
                                handleInputChange(
                                  valueInput as React.ChangeEvent<HTMLTextAreaElement>,
                                  question
                                );
                              }}
                              required
                              error={submitted}
                              helperText={submitted && "This field is required"}
                            />
                          </Grid>
                        );
                      case "textdrop":
                        return (
                          <>
                            <br />
                            <Grid item xs={6} key={question.id}>
                              <p>{question.name}</p>

                              <TextField
                                key={question.id}
                                fullWidth
                                name={question.description}
                                value={
                                  matchingAnswers.length > 0
                                    ? matchingAnswers[0]?.description
                                    : ""
                                }
                                variant="outlined"
                                onChange={(valueInput) => {
                                  const newText = {
                                    questionId: question.id,
                                    answer: valueInput.target.value,
                                  };

                                  if (matchingAnswers.length > 0) {
                                    matchingAnswers[0].description =
                                      valueInput.target.value;
                                    matchingAnswers[0] = matchingAnswers[0];
                                  }

                                  setTextController((prev) => ({
                                    ...prev,
                                    newText,
                                  }));
                                  handleInputChange(
                                    valueInput as React.ChangeEvent<HTMLTextAreaElement>,
                                    question
                                  );
                                }}
                                required
                                error={submitted && !question.name}
                                helperText={
                                  submitted &&
                                  !question.name &&
                                  "This field is required"
                                }
                              />
                            </Grid>
                            <Grid item xs={6} key={question.id}>
                              <p>{question.name}</p>
                              <OlogaGenericCombo
                                key={question.id}
                                label={question.name}
                                name={question.description}
                                items={question.answers}
                                value={
                                  matchingAnswers.length > 0
                                    ? matchingAnswers[0].description
                                    : ""
                                }
                                onChange={(valueInput: Answer) =>
                                  handleSelectChange(valueInput, question)
                                }
                                required={true}
                              />
                            </Grid>
                          </>
                        );
                      case "radio":
                        return (
                          <Grid
                            item
                            xs={6}
                            marginBottom={3}
                            key={`${question.id}-${question.description}`}
                          >
                            <p>{question.name}</p>

                            <RadioGroup
                              key={`${question.id}-${question.description}`}
                              aria-label="options"
                              name={question.name}
                              value={radioValues.get(question.description)}
                              onChange={(valueInput) =>
                                handleInputChangeRadio(valueInput, question)
                              }
                            >
                              {question.answers.map((item) => (
                                <FormControlLabel
                                  key={`${question.id}-${question.description}`}
                                  value={item["name"]}
                                  control={<Radio />}
                                  label={item["name"]}
                                />
                              ))}
                            </RadioGroup>
                          </Grid>
                        );
                      case "button":
                        return (
                          <Grid item xs={6} key={question.id}>
                            <Grid item className="form-buttons">
                              <SubmitButton
                                label={"Save and Create New"}
                                onClick={() => {}}
                              />
                            </Grid>
                          </Grid>
                        );

                      /*
                    case "filebutton":
                      return (
                        <Grid item xs={12} key={question.id}>
                          <h3>Upload Image</h3>
                          <FormControl fullWidth key={question.id}>
                            <Input
                              type="file"
                              //accept="image/*"
                              onChange={(
                                valueInput: ChangeEvent<HTMLInputElement>
                              ) => handleImageUpload(valueInput, question)}
                            />
                          </FormControl>
                          {(selectedImage || productData.imageUri) && (
                            <div>
                              <h2>Uploaded Image:</h2>
                              <img
                                src={
                                  selectedImage
                                    ? (selectedImage as string)
                                    : productData.imageUri
                                }
                                width="15%"
                                height="auto"
                              ></img>
                            </div>
                          )}
                        </Grid>
                      );
                    */
                    }
                  })}
                  <Grid
                    container
                    marginTop={5}
                    spacing={3}
                    mt={2}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {" "}
                    <Grid item className="form-buttons">
                      <SubmitButton label={"Save"} onClick={handleSubmit} />
                    </Grid>
                    <Grid item marginRight={2}>
                      <SubmitButton
                        label={"Cancel"}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default FormBuilder;
