import {
  Logout,
  ProductionQuantityLimits,
  AccountBalance,
  Addchart,
} from "@mui/icons-material";
import {
  PieChart,
  Calendar,
  CreditCard,
  Layers,
  Gift,
  Users,
  Settings,
  LogOut,
} from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";
import GroupsIcon from "@mui/icons-material/Groups";
import RuleIcon from "@mui/icons-material/Rule";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import MenuIcon from "@mui/icons-material/Menu";

const allRoles = [
  "ROLE_ADMIN",
  "ROLE_OPERATOR",
  "ROLE_CANDIDATE",
  "ROLE_VENDOR_SUPERVISOR",
  "ROLE_MERCHANT",
  "ROLE_MERCHANT_REORDER",
];

const coreRoles = ["ROLE_ADMIN", "ROLE_OPERATOR"];
const vendorTeamRoles = ["ROLE_VENDOR", "ROLE_VENDOR_SUPERVISOR"];

const pagesSection = [
  {
    href: "/assessment-admin/options",
    icon: MenuIcon,
    title: "Menu Principal",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
  },
  {
    href: "/assessment-candidate/options",
    icon: MenuIcon,
    title: "Menu Principal",
    roles: ["ROLE_CANDIDATE"],
  },
  {
    href: "/dashboard",
    icon: MenuIcon,
    title: "Dashboard",
    roles: ["ROLE_CANDIDATE"],
  },
  // {
  //   href: "/candidates/info-create",
  //   icon: MenuIcon,
  //   title: "Questionário de Recolha de Informação do Candidato",
  //   roles: ["ROLE_CANDIDATE"],
  // },
  {
    href: "/assessment-admin",
    icon: GroupsIcon,
    title: "Beneficiários e Demandas",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/candidates/info-create",
        title: "Questionário de Recolha de Informação dos Candidato",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/supply-mapping",
        title: "Mapeamento de Oferta",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/demand-mapping",
        title: "Mapeamento de Demanda",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/assessment-admin",
    icon: RuleIcon,
    title: "Qualificação e Selecção",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/assessment-admin/search",
        title: "Ferramenta de Pré-selecção de Beneficiários",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      // {
      //   href: "/assessment-admin/options",
      //   title: "Avaliação Legal e de Compliance",
      //   roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      // },
      // {
      //   href: "/assessment-admin/options",
      //   title: "Priorização dos Beneficiários",
      //   roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      // },
      {
        href: "/candidates/create",
        title: "Avaliação de Risco",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/qualification",
        title: "Listagem dos Beneficiarios Pré-Seleccionados",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/assessment-admin",
    icon: AssistantPhotoIcon,
    title: "Selecção da Assistência",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/tool/matchmaking",
        title: "Matchmaking",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/gap-assessment",
        title: "Gap Assessment",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/action-plan",
        title: "Plano de Acção",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      // {
      //   href: "/assessment-admin/options",
      //   title: "Mapeamento de Parceiros",
      //   roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      // },
    ],
  },
  {
    href: "/users",
    icon: Users,
    title: "Utilizadores",
    roles: [...allRoles],
    children: [
      {
        href: "/users/add-user",
        title: "Adicionar Novo",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/users/import-users",
        title: "Importar",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
      {
        href: "/users",
        title: "Pesquisar",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        //href: "#",
        title: "Trocal Senha",
        roles: [...allRoles],
      },
    ],
  },
  {
    href: "/Indicators/search",
    icon: Addchart,
    title: "Indicadores",
    roles: ["ROLE_ADMIN"],
  },
  {
    href: "/settings/search",
    icon: Settings,
    title: "Configurações",
    roles: ["ROLE_ADMIN"],
  },
  {
    href: "/logout",
    icon: Logout,
    title: "Sair",
    roles: [...allRoles],
  },
] as SidebarItemsType[];

const filterPagesByRole = (role: string) => {
  const userMenu = pagesSection.filter((p: SidebarItemsType) =>
    p.roles?.includes(role)
  );
  userMenu.forEach((menu: SidebarItemsType) => {
    menu.children = menu.children?.filter((ch) => ch.roles?.includes(role));
  });

  return userMenu;
};

const getNavItems = (role: string) => {
  return [
    {
      title: "",
      pages: role ? filterPagesByRole(role) : pagesSection,
    },
  ];
};

export default getNavItems;
