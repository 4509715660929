import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import GapAssessmentForm from "./GapAssessmentForm";
import { OlogaButton } from "../../components/Buttons/OlogaButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const GapAssessment: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackFormModal, setOpenFeedbackFormModal] = useState(false);
  const [gapAssessmentDto, setGapAssessmentDto] = useState({});
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
  const handleOpenFeedbackFormModal = () => setOpenFeedbackFormModal(true);
  const handleCloseFeedbackFormModal = () => setOpenFeedbackFormModal(false);
  const [candidate, setCandidate] = useState({});
  const [uri, setUri] = useState("");

  const columns: GridColDef[] = [
    {
      field: "candidateReference",
      headerName: "Referência do Candidato",
      flex: 2,
      editable: false,
    },
    {
      field: "candidateName",
      headerName: "Nome",
      flex: 3,
      editable: false,
    },

    {
      field: "companyFoundationYear",
      headerName: "Ano de Fundação da Empresa",
      flex: 2,
      editable: false,
    },
    {
      field: "category",
      headerName: "Categoria",
      flex: 2,
      editable: false,
    },
    {
      field: "province",
      headerName: "Província",
      flex: 2,
      editable: false,
    },

    {
      field: "district",
      headerName: "Distrito",
      flex: 2,
      editable: false,
    },
    {
      field: "companyType",
      headerName: "Tipo de Empresa",
      flex: 4,
      editable: false,
    },
    {
      field: "numberOfEmployees",
      headerName: "Número de Funcionários",
      flex: 1.5,
      editable: false,
    },
    {
      field: "candidatureStatus",
      headerName: "Estado",
      flex: 2,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 3.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          startIcon={<VisibilityOutlinedIcon />}
          onClick={() => {
            console.log(`/gap-assessment/candidate/${params.id}`);
            setUri(`/gap-assessment/candidate/${params.id}`);
            setCandidate({ ...params.row, candidateId: params.id });
            handleOpenFeedbackModal();
          }}
        >
          Ver Gaps
        </Button>,
        // <Button
        //   size="small"
        //   variant="outlined"
        //   startIcon={<VisibilityOutlinedIcon />}
        //   onClick={() => {
        //     setCandidate({ ...params.row, candidateId: params.id });
        //     handleOpenFeedbackFormModal();
        //   }}
        // >
        //   Atribuir Gap
        // </Button>,
      ],
    },
  ];

  const Gapcolumns: GridColDef[] = [
    {
      field: "analysCategory",
      headerName: "Categorias de Analise",
      flex: 2,
      editable: false,
    },
    {
      field: "gap",
      headerName: "Gap",
      flex: 3,
      editable: false,
    },

    {
      field: "specificAditionalNeeds",
      headerName: "Necessidades Adicionais Especificas",
      flex: 2,
      editable: false,
    },
    {
      field: "comments",
      headerName: "Comentarios",
      flex: 2,
      editable: false,
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 0.5,
    //   editable: false,
    //   type: "actions",
    //   getActions: (params) => [
    //     <Button
    //       size="small"
    //       variant="outlined"
    //       startIcon={<VisibilityOutlinedIcon />}
    //       onClick={() => {
    //         console.log("log:", params);
    //         navigate(/assessments/edit, {
    //           state: { id: params.id },
    //         });
    //       }}
    //     >
    //       View
    //     </Button>,
    //   ],
    // },
  ];

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Gap Assessment"}
        // buttonLabel="Adicionar Novo Gap Assessment"
        // onClick={handleOpenFeedbackModal}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"/candidate"} columns={columns} />
          </Card>

          <Modal open={openFeedbackModal} onClose={handleCloseFeedbackModal}>
            <Box sx={style}>
              <Typography variant="h6" component="h2" mb={2}>
                Lista de Gap Assessment
              </Typography>
              <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<VisibilityOutlinedIcon />}
                    onClick={() => {
                      // setCandidate({ ...params.row, candidateId: params.id });
                      handleOpenFeedbackFormModal();
                    }}
                  >
                    Atribuir Gap
                  </Button>
                </>
              </Box>
              <br />
              <Card>
                <OlogaDataGrid url={uri} columns={Gapcolumns} />
              </Card>

              <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <>
                  <OlogaButton
                    label={"Fechar"}
                    onClick={handleCloseFeedbackModal}
                  />
                </>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openFeedbackFormModal}
            onClose={handleCloseFeedbackFormModal}
          >
            <Box sx={style}>
              <Typography variant="h6" component="h2" mb={2}>
                Formulário de Gap Assessment
              </Typography>
              <GapAssessmentForm
                onClose={handleCloseFeedbackFormModal}
                dto={candidate}
                handleOpenGapList={handleOpenFeedbackModal}
              />
            </Box>
          </Modal>
          <OlogaBackButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GapAssessment;
