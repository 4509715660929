import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  CardContent,
  Button,
  Modal,
  Typography,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import { toast } from "react-toastify";
import BackButton from "../../components/Buttons/BackButton";
import ActionPlanForm from "../actionPlan/ActionPlanForm";
import http from "../../utils/axios";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { OlogaButton } from "../../components/Buttons/OlogaButton";

const Card = styled(MuiCard)(spacing);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ActionPlanPage: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackGapModal, setOpenFeedbackGapModal] = useState(false);
  const [openActionPlanModal, setOpenActionPlanModal] = useState(false);
  const [assessmentId, setAssessmentId] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState<any>(0);
  const [actiomPlanDto, setActionPlanDto] = useState<any>();
  const [candidate, setCandidate] = useState({});
  const [uri, setUri] = useState("");

  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
  const handleOpenActionPlanModal = () => setOpenActionPlanModal(true);
  const handleCloseActionPlanModal = () => setOpenActionPlanModal(false);
  const handleOpenFeedbackGapModal = () => setOpenFeedbackGapModal(true);
  const handleCloseFeedbackGapModal = () => setOpenFeedbackGapModal(false);
  const statusItems = [
    {
      id: 0,
      description: "COMPLETE ASSESSMENT",
      type: "COMPLETED",
      name: "COMPLETED",
      value: 0,
      quantity: 0,
    },
    {
      id: 1,
      description: "ASSIGNED TO CANDIDATE",
      type: "ASSIGNED",
      name: "ASSIGNED",
      value: 1,
      quantity: 1,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "fullNameCandidate",
      headerName: "Candidatura",
      flex: 1,
      editable: false,
    },
    {
      field: "fullNameGap",
      headerName: "Gap",
      flex: 1,
      editable: false,
    },
    {
      field: "analysCategory",
      headerName: "Analise de Categoria",
      flex: 1,
      editable: false,
    },
    {
      field: "subActivitySector",
      headerName: "Sub Sector de Atividade",
      flex: 1.5,
      editable: false,
    },
    {
      field: "impact",
      headerName: "Impacto",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Estado",
      flex: 1.5,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 2,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <>
          {params.row.status == "No Action Plan found" ? (
            <Button
              size="small"
              variant="outlined"
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => {
                console.log(params.row);
                setActionPlanDto(params.row as any);
                setAssessmentId(params.id as number);
                handleOpenActionPlanModal();
              }}
            >
              Adicionar Plano de Acção
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => {
                console.log(params.row);
                setActionPlanDto(params.row as any);
                setAssessmentId(params.id as number);
                handleOpenActionPlanModal();
              }}
            >
              Atualizar Plano de Acção
            </Button>
          )}
        </>,
      ],
    },
  ];
  const Gapcolumns: GridColDef[] = [
    {
      field: "analysCategory",
      headerName: "Categorias de Analise",
      flex: 2,
      editable: false,
    },
    {
      field: "gap",
      headerName: "Gap",
      flex: 3,
      editable: false,
    },

    {
      field: "specificAditionalNeeds",
      headerName: "Necessidades Adicionais Especificas",
      flex: 2,
      editable: false,
    },
    {
      field: "comments",
      headerName: "Comentarios",
      flex: 2,
      editable: false,
    },
    {
      field: "status",
      headerName: "Estado",
      flex: 2,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 4,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <>
          {params.row.status == "No Action Plan found" ? (
            <Button
              size="small"
              variant="outlined"
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => {
                console.log(params.row);
                setActionPlanDto(params.row as any);
                setAssessmentId(params.id as number);
                handleOpenActionPlanModal();
              }}
            >
              Adicionar Plano de Acção
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => {
                console.log(params.row);
                setActionPlanDto(params.row as any);
                setAssessmentId(params.id as number);
                handleOpenActionPlanModal();
              }}
            >
              Atualizar Plano de Acção
            </Button>
          )}
        </>,
      ],
    },
  ];
  const columnsCad: GridColDef[] = [
    {
      field: "candidateReference",
      headerName: "Referência do Candidato",
      flex: 2,
      editable: false,
    },
    {
      field: "candidateName",
      headerName: "Nome",
      flex: 3,
      editable: false,
    },

    {
      field: "companyFoundationYear",
      headerName: "Ano de Fundação da Empresa",
      flex: 2,
      editable: false,
    },
    {
      field: "category",
      headerName: "Categoria",
      flex: 2,
      editable: false,
    },
    {
      field: "province",
      headerName: "Província",
      flex: 2,
      editable: false,
    },

    {
      field: "district",
      headerName: "Distrito",
      flex: 2,
      editable: false,
    },
    {
      field: "companyType",
      headerName: "Tipo de Empresa",
      flex: 4,
      editable: false,
    },
    {
      field: "numberOfEmployees",
      headerName: "Número de Funcionários",
      flex: 1.5,
      editable: false,
    },
    {
      field: "candidatureStatus",
      headerName: "Estado",
      flex: 2,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 3.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          startIcon={<VisibilityOutlinedIcon />}
          onClick={() => {
            let q = "";

            const row: any = {
              id: params.id,
              candidateName: params.row["candidateName"],
              province: params.row["province"],
              district: params.row["district"],
            };
            for (let key in row) {
              if (row.hasOwnProperty(key)) {
                let value = row[key];
                if (value) {
                  q += key + "=" + value + "&";
                }
              }
            }
            setUri(
              q.length > 0
                ? "/actionPlan/search/gap-assessment/q?" + q.slice(0, -1)
                : "/actionPlan/search/gap-assessment/q"
            );
            console.log(
              "/actionPlan/search/gap-assessment/q?" + q.slice(0, -1)
            );
            setCandidate({ ...params.row, candidateId: params.id });
            handleOpenFeedbackGapModal();
          }}
        >
          Ver Gaps
        </Button>,
      ],
    },
  ];

  const handleSubmitFeedBack = async (
    values: any,
    { resetForm, setSubmitting }: any
  ) => {
    const feedback = {
      assessmentScore: { id: assessmentId },
      assessmentScoreStatus: selectedStatus,
      ...values,
    };
    try {
      await http.post(`/assessments/feedback`, feedback);
      resetForm();
      handleCloseFeedbackModal();
      toast.success("Feedback submitted");
    } catch (error) {
      toast.error("Some Error Occur");
    }
    setSubmitting(false);
  };

  const handleSelectChangeStatus = (status: any) => {
    setSelectedStatus(status.id);
  };

  return (
    <>
      <OlogaPageHeader title="Plano de Acção" />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"/candidate"} columns={columnsCad} />
          </Card>
        </Grid>
      </Grid>

      <Modal open={openFeedbackGapModal} onClose={handleCloseFeedbackGapModal}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" mb={2}>
            Lista de Plano de Acções
          </Typography>
          {/* <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<VisibilityOutlinedIcon />}
                    onClick={() => {
                      // setCandidate({ ...params.row, candidateId: params.id });
                      handleOpenFeedbackFormModal();
                    }}
                  >
                    Atribuir Plano de A 
                  </Button>
                </>
              </Box> */}
          <br />
          <Card>
            <OlogaDataGrid url={uri} columns={Gapcolumns} />
          </Card>
          <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <>
              <OlogaButton
                label={"Fechar"}
                onClick={handleCloseFeedbackGapModal}
              />
            </>
          </Box>
        </Box>
      </Modal>

      {/* <Modal
        open={openFeedbackModal}
        onClose={handleCloseFeedbackModal}
        aria-labelledby="modal-feedback-title"
        aria-describedby="modal-feedback-description"
      >
        <Box sx={style}>
          <Formik initialValues={{}} onSubmit={handleSubmitFeedBack}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                  Feedback
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <OlogaGenericCombo
                      label="Feedback State"
                      name="status"
                      items={statusItems}
                      value={selectedStatus || 0}
                      onChange={handleSelectChangeStatus}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <OlogaTextfield name="name" label="Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <OlogaTextfield
                      name="description"
                      label="Detailed Feedback"
                      multiline
                      rows={5}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button variant="contained" type="submit">
                    Submit Feedback
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal> */}

      <Modal open={openActionPlanModal} onClose={handleCloseActionPlanModal}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" mb={2}>
            Plano de Ação Form
          </Typography>
          <ActionPlanForm
            assessmentScoreId={assessmentId}
            onClose={handleCloseActionPlanModal}
            actionPlanDto={actiomPlanDto}
          />
        </Box>
      </Modal>

      <BackButton />
    </>
  );
};

export default ActionPlanPage;
